import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import PageTitle from "../components/page-title"
import { Container, Row } from "reactstrap"

const OrderOnline = () => {
  // const [script, setScript] = useState(false)

  // useEffect(() => {
  //   console.log(script)
  //   setScript(true)
  // }, [script])

  return (
    <Layout>
      <SEO
        title="Order Online"
        keywords="ribsusabbq, ribs usa, ribs usa bbq, ribs, usa, bbq, best bbq near me, baby back ribs, beef ribs near me, best beef ribs near me, bbq chicken near me"
      />

      <PageTitle title="Order Online" />
      <section id="order-online">
        <Container>
          <Row className="order-link">
            <div className="col-12 col-sm-6 for-sm">
              <div className="text-center order-box ">
                <h3>Order Online</h3>
                <a
                  className="btn my-button order-our-button"
                  href="https://www.foodbooking.com/ordering/restaurant/menu?restaurant_uid=741d59ba-8ae1-4ba8-99d9-6f14f63a2113"
                  download
                >
                  Order For Pickup
                </a>
                {/* {script && (
                  <>
                    <span
                      //   onClick={() => setScript(true)}
                      className="btn my-button order-us"
                      data-glf-cuid="475819fd-0e52-4046-ad9d-3f2c72023730"
                      data-glf-ruid="741d59ba-8ae1-4ba8-99d9-6f14f63a2113"
                    >
                      Order for Pickup
                    </span>

                    <script
                      src="https://www.fbgcdn.com/embedder/js/ewm2.js"
                      defer
                      async
                    ></script>
                    <script
                      data-account="EtEY21YJJD"
                      src="https://cdn.userway.org/widget.js"
                    ></script>
                  </>
                )} */}
              </div>
            </div>
           
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default OrderOnline
